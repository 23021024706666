import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader
} from "@mui/material";
import { useTranslation } from "_metronic/i18n/language";
import { DashboardApiService } from "app/services";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import store from "app/store/store";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { extractDate } from "./utils/extractDate";
import { Col, Form, Row } from "react-bootstrap";
import Loading from "app/pages/home/components/Loading";
import { QuizType } from "app/types";
import { ISelectSearchQuiz } from "interfaces";

const DashExportQuizAnswers: React.FC = () => {
  const translate = useTranslation();
  const { auth } = store.getState();

  const api = new AnanseApiService();
  const dashBoardApi = new DashboardApiService();

  const today = new Date().toISOString().split("T")[0];
  const [loading, setLoading] = useState(false);
  const [loadingQuizzes, setLoadingQuizzes] = useState(false);
  const [quizzes, setQuizzes] = useState<QuizType[]>([]);
  const [quizSelected, setQuizSelected] = useState({} as ISelectSearchQuiz);
  const [mode, setMode] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(today);

  const getQuiz = async () => {
    setLoadingQuizzes(true);
    try {
      const { total } = await api.makeHttpRequest({
        method: "GET",
        url: `/quiz?Type=1&UserId=${auth.user.id}`
      });
      const { data } = await api.makeHttpRequest({
        method: "GET",
        url: `/quiz?PerPage=${total}&Type=1&UserId=${auth.user.id}`
      });
      console.log(data);
      const filteredData = data.filter((quiz: any) => quiz.type !== "5");

      console.log(filteredData);
      setQuizzes(filteredData);
    } catch (error) {
    } finally {
      setLoadingQuizzes(false);
    }
  };

  useEffect(() => {
    getQuiz();
  }, []);

  const handleExport = async () => {
    const formatedStartDate = extractDate(startDate);
    const formatedEndDate = extractDate(endDate);
    const selectedMode = selectInput.find(input => input.key === Number(mode))
    ?.type;
    try {
      setLoading(true);
      await dashBoardApi.download(
        {
          url: `/export-quizanswer?UserId=${auth.user.id}&QuizId=${quizSelected.id}&StartDate=${formatedStartDate}&EndDate=${formatedEndDate}&Mode=${selectedMode}`
        },
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      Swal.fire({
        title: translate("defaultMessages_success"),
        text: translate("labels_start_download"),
        icon: "success"
      });
    } catch (err) {
      const error: any = err;
      console.error("Error exporting quiz answers:", error);
      if (!error) {
        return;
      }
      if (error?.response?.request.status === 400) {
        Swal.fire({
          title: translate("dashboard.no.data"),
          icon: "error"
        });
      }
      if (error?.response?.request.status === 500) {
        Swal.fire({
          title: translate("dashboard.error"),
          icon: "error"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setQuizSelected({} as ISelectSearchQuiz);
    setMode("");
  };

  const selectInput = [
    {
      key: 1,
      type: "row",
      name: translate("dashboard_mode_row")
    },
    {
      key: 2,
      type: "column",
      name: translate("dashboard_mode_column")
    }
  ];

  return (
    <Box>
      <Loading isLoading={loading} />
      <Card>
        <CardHeader title={translate("screens_quiz_export")} />
        <CardContent>
          <Form.Group as={Col} lg="12" xs="12">
            <Form.Label>{translate("screens_quiz_graphic")}</Form.Label>
            <Form.Select
              value={quizSelected.id}
              onChange={e => {
                const selectedQuiz = quizzes.find(
                  quiz => quiz.quizId.toString() === e.target.value
                );
                if (selectedQuiz) {
                  setQuizSelected({
                    id: selectedQuiz.quizId,
                    label: selectedQuiz.name,
                    value: selectedQuiz.quizId,
                    obj: selectedQuiz
                  });
                }
              }}
            >
              {loadingQuizzes ? (
                <option value="">{translate("defaultMessages_loading")}</option>
              ) : (
                <option value="">
                  {translate("screens_quiz_selectTest_title")}
                </option>
              )}
              {quizzes.map(quiz => (
                <option key={quiz.quizId} value={quiz.quizId}>
                  {quiz.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Row>
            <Form.Group
              as={Col}
              lg="6"
              xs="12"
              className="mb-0 pt-2"
              controlId="startDate"
            >
              <Form.Label>{translate("dashboard_start_date")}</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                max={today}
                onChange={e => setStartDate(e.target.value)}
              />
            </Form.Group>

            <Form.Group
              as={Col}
              lg="6"
              xs="12"
              className="mb-0 pt-2"
              controlId="endDate"
            >
              <Form.Label>{translate("dashboard_end_date")}</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                max={today}
                onChange={e => setEndDate(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Form.Group as={Col} lg="12" xs="12" className="mb-0 pt-2">
            <Form.Label>{translate("dashboard_export_mode")}</Form.Label>
            <Form.Select value={mode} onChange={e => setMode(e.target.value)}>
              <option value="">
                {translate("dashboard_select_export_mode")}
              </option>
              {selectInput.map(value => (
                <option key={value.key} value={value.key}>
                  {value.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </CardContent>
        <CardActions style={{ justifyContent: "flex-end" }}>
          <Button
            color="warning"
            variant="contained"
            onClick={handleClear}
            disabled={loading}
            style={{ marginRight: 10 }}
          >
            {translate("buttons_clean")}
          </Button>
          <Button
            color="success"
            variant="contained"
            onClick={handleExport}
            disabled={loading || !quizSelected.id || !mode}
          >
            {loading
              ? translate("dashboard_loading")
              : translate("datatable_export")}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default DashExportQuizAnswers;
