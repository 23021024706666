import { Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { CampaignType, QuizType, RelatedQuizType } from "app/types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslation } from "_metronic/i18n/language";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";
import { QuizApiService } from "app/services/Ananse/QuizApiService";
import SelectLanguage from "app/pages/admin/components/SelectLanguage";
import Loading from "app/pages/home/components/Loading";
import { QuestionApiService } from "app/services/Ananse/QuestionApiService";
import MultipleSelectCheckmarks from "../component/MultipleSelectCheckmarks";
import { QuestionAnamneseForm, QuestionsAnamneseList } from "../Questions";
import DialogSelectUsers from "../../Quiz/component/DialogSelectUsers";

const schema = yup.object().shape({
  name: yup.string().required("O nome é obrigatório"),
  campaignId: yup
    .number()
    .required("A campanha é obrigatória")
    .positive("O número deve ser positivo")
    .integer("Deve ser um número inteiro")
    .typeError("Selecione uma campanha válida"),
  language: yup.string().required("O Idioma é obrigatória"),
  // userId: yup.string().required("Ao menos um Usuário deve ser associado")
});

const AnamneseFormNew: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const translate = useTranslation();
  const [openAnamneseModal, setOpenQuestionModal] = useState(false);
  const [quizData, setQuizData] = useState<QuizType | null>(null);
  const [questions, setQuestions] = useState<any[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<any | null>(null);
  const [campaigns, setCampaigns] = useState<CampaignType[]>([]);
  const [campaign, setCampaign] = useState<CampaignType>({} as CampaignType);
  const [userIds, setUserIds] = useState<string>("");
  const [language, setLanguage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useForm<QuizType>({
    resolver: yupResolver(schema),
    defaultValues: {
      quizId: 0,
      campaignId: 0,
      name: "",
      language: "",
      publishedAt: new Date().toISOString(),
      type: "2",
      userId: "",
      anamneseId: 0,
      campaign: {} as CampaignType,
      mandatoryAnamnese: false,
      relatedByQuiz: {} as RelatedQuizType,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    }
  });

  const quizService = new QuizApiService();
  const api = new AnanseApiService();
  const questionService = new QuestionApiService();

  useEffect(() => {
    if (id) {
      getQuizById(Number(id));
    }
    getCampaigns();
  }, [id]);

  const getQuizById = async (quizId: number) => {
    try {
      setLoading(true);
      const quiz = await quizService.getById(quizId);
      setCampaign(quiz.campaign);
      setValue("name", quiz.name);
      setValue("campaignId", quiz.campaignId);
      setLanguage(quiz.language);
      setValue("publishedAt", quiz.publishedAt);
      setValue("validatedAt", quiz.validatedAt);
      setValue("type", quiz.type);
      setValue("maxShots", quiz.maxShots);
      setUserIds(quiz.userId);
      setValue("anamneseId", quiz.anamneseId);
      setValue("mandatoryAnamnese", quiz.mandatoryAnamnese);
      setQuestions(quiz.questions || []);
      setQuizData(quiz);
    } catch (error) {
      console.error("Erro ao buscar o quiz:", error);
    } finally {
      setLoading(false);
    }
  };

  const getCampaigns = async () => {
    try {
      const { data } = await api.makeHttpRequest({
        method: "GET",
        url: "/campaigns"
      });
      setCampaigns(data || []);
    } catch (error) {
      console.error("Erro ao buscar campanhas:", error);
      setCampaigns([]);
    }
  };

  const onSubmit = async (data: QuizType) => {
    setLoading(true);
    try {
      const prepareData: QuizType = { ...data,userId: userIds, };
      if (id) {
        await quizService.update(id, prepareData);
      } else {
        const response = await quizService.create(prepareData);
        await getQuizById(response.quizId);
        setOpenQuestionModal(true);
      }
      Swal.fire({
        title: translate("defaultMessages_success"),
        text: "Teste criado com sucesso!",
        icon: "success"
      });
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Tente novamente",
        icon: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const onEdit = (question: any) => {
    setSelectedQuestion(question);
    setOpenQuestionModal(true);
  };

  const onDelete = async (quizQuestionId: number) => {
    const result = await Swal.fire({
      title: translate("deleteRegister_title"),
      text: translate("deleteRegister_message"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: translate("buttons.confirm"),
      cancelButtonText: translate("buttons.cancel")
    });

    if (result.isConfirmed) {
      try {
        setLoading(true);
        await questionService.delete(quizQuestionId);
        await getQuizById(Number(id) || quizData?.quizId! || watch("quizId"));
        Swal.fire(
          translate("defaultMessages_success"),
          translate("deleteRegister_deletedSuccess"),
          "success"
        );
      } catch (error) {
        Swal.fire(
          translate("dashboard_error"),
          translate("deleteRegister_deletedError"),
          "error"
        );
      }
    }
  };

  const helperText = (text: string) => (
    <Typography color={"red"}>{text}</Typography>
  );

  const onCleanQuestion = () =>{
    setSelectedQuestion(null);
    setOpenQuestionModal(false);
  }

  return (
    <div style={{ padding: "10px" }}>
      <Loading isLoading={loading} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card style={{ marginBottom: 5 }}>
          <Card.Header>
            {translate("screens.quiz.labels.principal")}
          </Card.Header>
          <Card.Body>
            <Row>
              <Form.Group as={Col} lg="6" xs="12" className="mb-0 pt-2">
                <Form.Label>
                  {translate("screens.quiz.labels.name")} *
                </Form.Label>
                <Form.Control {...register("name", { required: true })} />
                {errors.name && helperText(errors.name?.message!)}
              </Form.Group>

              <Form.Group as={Col} lg="6" xs="12" className="mb-0 pt-2">
                <Form.Label>
                  {translate("screens.quiz.labels.campaign")} *
                </Form.Label>
                <Controller
                  name="campaignId"
                  control={control}
                  render={({ field }) => (
                    <Form.Select
                      placeholder={translate("screens.quiz.labels.campaign")}
                      value={field.value}
                      onChange={e => {
                        field.onChange(Number(e.target.value));
                      }}
                    >
                      <option value="">
                        {translate("screens.quiz.placeholders.campaign")}
                      </option>
                      {campaigns.map((campaign, index) => (
                        <option
                          key={`index${index}-${campaign.campaignId}`}
                          value={campaign.campaignId.toString()}
                        >
                          {campaign.name}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                />
                {errors.campaignId && helperText(errors.campaignId?.message!)}
              </Form.Group>

              <Form.Group as={Col} lg="6" xs="12" className="mb-0 pt-2">
                <Form.Label>
                  {translate("screens.quiz.labels.language")}
                </Form.Label>
                <SelectLanguage
                  handleChangeLanguage={(lang: { value: string }) => {
                    setLanguage(lang.value);
                    setValue("language", lang.value);
                  }}
                  languageValue={language}
                />
                {errors.language && helperText(errors.language.message!)}
              </Form.Group>
              <Form.Group as={Col} lg="6" xs="12" className="mb-0 pt-2">

                <Stack>
                  <Form.Label>
                    {translate("screens_quiz_users_associated")} *
                  </Form.Label>
                  <DialogSelectUsers
                    userIds={userIds}
                    setUserIds={setUserIds}
                  />
                  {errors.userId && helperText(errors.userId?.message!)}
                </Stack>
              </Form.Group>
            </Row>
          </Card.Body>
          <Card.Footer>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Link to={"/admin/anamnese"}>
                <Button type="button" color="error" variant="contained">
                  {translate("buttons_cancel")}
                </Button>
              </Link>
              <Button type="reset" color="warning" variant="contained">
                {translate("buttons_clean")}
              </Button>
              <Button type="submit" color="success" variant="contained">
                {translate(id ? "buttons_edit" : "buttons_save")}
              </Button>
            </Stack>
          </Card.Footer>
        </Card>
      </Form>
      {Number(id) || quizData?.quizId! || watch("quizId") ? (
        <QuestionsAnamneseList
          collections={campaign.collection}
          questions={questions}
          quiz={quizData || ({} as QuizType)}
          onEdit={onEdit}
          onDelete={onDelete}
          onAdd={() => setOpenQuestionModal(true)}
          onRefresh={() => {
            getQuizById(Number(id) || quizData?.quizId! || watch("quizId"));
            onCleanQuestion()
          }}
        />
      ) : null}

      <QuestionAnamneseForm
        collections={campaign.collection}
        handleClose={() => setOpenQuestionModal(false)}
        language={watch(`language`)}
        open={openAnamneseModal}
        question={selectedQuestion}
        onFinish={() => {
          getQuizById(Number(id) || quizData?.quizId! || watch("quizId"));
          onCleanQuestion();
        }}
        quizId={Number(id) || quizData?.quizId! || watch("quizId")}
        quiz={quizData || ({} as QuizType)}
      />
    </div>
  );
};

export default AnamneseFormNew;
