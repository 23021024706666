import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Add, Clear, Remove } from "@mui/icons-material";
import { useTranslation } from "_metronic/i18n/language";
import { addUpdate } from "app/services/uploadFileToAzureBlob";
import { ColletionType, FileType, QuestionType, QuizType } from "app/types";
import { selectInput } from "../../mock";
import Loading from "app/pages/home/components/Loading";

type CadastroFormModalProps = {
  collections: ColletionType[];
  language: any;
  open: boolean;
  quiz: QuizType;
  quizId: number;
  question?: QuestionType;
  handleClose: () => void;
  onFinish: () => void;
};

const defaultValues = {
  quizId: 0,
  quizQuestionId: 0,
  questionId: 0,
  quest: "",
  description: "",
  fragranceId: "",
  rightAnswer: "",
  type: "",
  file: {} as FileType,
  answers: [
    {
      quizQuestionAnswerId: 0,
      answerId: 0,
      desc: "",
      description: "",
      nextQuizQuestionId: 0
    }
  ]
};

const schema = yup.object().shape({
  quest: yup.string().required("A pergunta é obrigatória"),
  type: yup.string().required("O tipo é obrigatório"),
  answers: yup
    .array()
    .of(
      yup.object().shape({
        desc: yup.string().required("A resposta é obrigatória")
      })
    )
    .min(1, "É necessário ter ao menos uma resposta")
});

const CadastroFormModal: React.FC<CadastroFormModalProps> = ({
  collections,
  language,
  open,
  question,
  quiz,
  quizId,
  onFinish,
  handleClose
}) => {
  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    resetField,
    formState: { errors }
  } = useForm<QuestionType>({
    resolver: yupResolver(schema),
    defaultValues
  });

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "answers"
  });
  const translate = useTranslation();
  const questions = quiz.questions || [];

  const [loading, setLoading] = useState(false);

  const selectedType = watch("type");

  const answers = watch("answers");

  useEffect(() => {
    if (question) {
      setValue("quizId", question.quizId || quizId);
      setValue("quizQuestionId", question.quizQuestionId);
      setValue("questionId", question.questionId);
      setValue("quest", question?.question?.quest || "");
      setValue("description", question.question?.description || "");
      setValue("fragranceId", question.fragranceId);
      setValue("rightAnswer", question.rightAnswer);
      setValue("type", question.type);
      setValue("arrangeQuestionSequence", question.arrangeQuestionSequence);

      replace(
        question.answers.map(answer => ({
          quizQuestionAnswerId: answer.quizQuestionAnswerId,
          answerId: answer.answerId,
          desc: answer.desc || answer.answer?.label || "",
          description: answer.description || answer.answer?.description || ""
        }))
      );
    } else {
      setValue("arrangeQuestionSequence", questions.length + 1);
    }
  }, [question, setValue, replace, open, quiz]);

  const onClose = () => {
    handleClose();
    reset(defaultValues);
    onFinish();
  };

  const onSubmit = async (data: QuestionType) => {
    setLoading(true);
    try {
      let message;
      const newData = {
        ...data,
        quizId: data.quizId || quizId
      };
      const response = await addUpdate(newData);
      message = data.quizQuestionId
        ? translate("registration_updated_successfully")
        : translate("crudMessages_successText");
      handleClose();

      // reset(response);
      onClose();
      Swal.fire({
        title: translate("defaultMessages_success"),
        text: message,
        icon: "success"
      });
    } catch (error) {
      let message;
      message = "Não foi possivel add ou atualiza perguta";
      handleClose();
      Swal.fire({
        title: translate("screenApp_capsula_error"),
        text: message,
        icon: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClearQuest = () => {
    setValue("quest", "");
    setValue("questionId", 0);
  };

  const handleQuestChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue("quest", e.target.value);
    if (!e.target.value) setValue("questionId", 0);
  };

  // Função para limpar o answerId ao alterar o desc
  const handleDescChange = (index: number, value: string) => {
    setValue(`answers.${index}.desc`, value);
    if (value === "") {
      setValue(`answers.${index}.answerId`, 0);
    }
  };

  const helperText = (text?: string) => (
    <Form.Text className="text-danger">{text}</Form.Text>
  );

  const sequence = watch("arrangeQuestionSequence");

  return (
    <>
      <Loading isLoading={loading} />
      <Dialog open={open} maxWidth="lg">
        <DialogTitle>
          {translate("screens_quiz_labels_questionarie")}
          {" - "}
          {sequence}
        </DialogTitle>
        <Divider style={{ backgroundColor: "#757575" }} />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent
            sx={{
              width: {
                md: "50vw"
              },
              minWidth: "320px",
              maxWidth: "800px",
              minHeight: "80dvh"
            }}
          >
            <Row>
              <Form.Group as={Col} lg="12" xs="12" className="mb-0 pt-2">
                <Form.Label>
                  {translate("screens.quiz.labels.question")} *
                </Form.Label>
                <Box display="flex" alignItems="center">
                  <Form.Control
                    placeholder={translate("screens.quiz.labels.question")}
                    {...register("quest")}
                    onChange={handleQuestChange}
                    as="textarea"
                    rows={1}
                  />
                  <InputAdornment position="end">
                    <IconButton
                      title={translate("buttons_clean")}
                      onClick={handleClearQuest}
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                </Box>
                {errors.quest && helperText(errors.quest.message)}
              </Form.Group>

              <Form.Group as={Col} lg="12" xs="12" className="mb-0 pt-2">
                <Form.Label>
                  {translate("screenApp.quiz.questionType")} *
                </Form.Label>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <Form.Select {...field}>
                      <option value="">
                        {translate("screenApp_quiz_selectOption")}
                      </option>
                      {selectInput.map((value, index) => (
                        <option value={value.type} key={index}>
                          {value.name}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                />
                {errors.type && helperText(errors.type.message)}
              </Form.Group>
            </Row>
            <Divider style={{ backgroundColor: "#757575", marginTop: 20 }} />
            <Typography variant="h6" mt={2} mb={2}>
              {translate("labels_answers")}
            </Typography>
            {fields.map((item, index) => (
              <Box key={item.id} mb={2}>
                <Row>
                  <Form.Group as={Col} lg={"8"} xs="12" className="mb-0 pt-1">
                    <Form.Label>
                      {translate("screens.quiz.labels.answer")} *
                    </Form.Label>
                    <Form.Control
                      disabled={false}
                      placeholder={translate("screens.quiz.labels.answer")}
                      {...register(`answers.${index}.desc` as const)}
                      onChange={e => handleDescChange(index, e.target.value)}
                      {...(errors.answers &&
                        helperText(errors.answers[index]?.desc?.message))}
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg={"4"} xs="12" className="mb-0 pt-4">
                    <Form.Label></Form.Label>
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<Remove />}
                      onClick={() => remove(index)}
                      sx={{ marginTop: 1 }}
                    >
                      {translate("screens_quiz_labels_deleteAnswer")}
                    </Button>
                  </Form.Group>
                </Row>
              </Box>
            ))}
            <Stack spacing={2}>
              <Divider style={{ backgroundColor: "#757575" }} />
              <Button
                variant="outlined"
                color="info"
                startIcon={<Add />}
                onClick={() =>
                  append({
                    quizQuestionAnswerId: 0,
                    answerId: 0,
                    desc: "",
                    description: "",
                    nextQuizQuestionId: 0
                  })
                }
              >
                {translate("screens_quiz_labels_addAnswer")}
              </Button>
            </Stack>

            <Form.Group as={Col} lg={"12"} xs="12" className="mb-0 pt-2">
              <Form.Label>
                {translate("screenApp.results.rightResponse")}
              </Form.Label>
              <Controller
                name="rightAnswer"
                control={control}
                render={({ field }) => (
                  <Form.Select {...field}>
                    <option value="">
                      {translate("screenApp.quiz.selectOption")}
                    </option>
                    {answers.map((item, index) => (
                      <option key={index} value={item.desc}>
                        {item.desc}
                      </option>
                    ))}
                  </Form.Select>
                )}
              />
            </Form.Group>
          </DialogContent>
          <Divider style={{ backgroundColor: "#757575" }} />
          <DialogActions>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button variant="contained" color="error" onClick={onClose}>
                {translate("buttons_cancel")}
              </Button>
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  reset(defaultValues);
                }}
              >
                {translate("buttons_clean")}
              </Button>
              <Button type="submit" variant="contained" color="success">
                {translate("buttons_send")}
              </Button>
            </Stack>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

export default CadastroFormModal;
